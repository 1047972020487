<script setup>
import {useRuntimeConfig} from "nuxt/app";
import {onMounted, ref} from "vue";
import {useAuthStore} from "~/stores/auth";

const auth = useAuthStore();
const runtimeConfig = useRuntimeConfig()
const recaptchaSiteKey = ref(runtimeConfig.public.recaptchaSiteKey)
const isSubscribed = ref(false)
const subscribeLoading = ref(false)
const formError = ref('')
const firstName = ref('')
const email = ref('')
const errors = ref([])
const hidePanel = ref(false)

const props = defineProps({
  showIfSubscribed: {
    type: Boolean,
    required: false,
    default: true,
  },
})

useHead({
  script: [
    {src: `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey.value}`, async: true, defer: true}
  ]
})

onMounted(() => {
  const newsletterEmail = process.client ? localStorage.getItem('newsletter-email') || null : null

  if (newsletterEmail) {
    isSubscribed.value = true
  }

  if (newsletterEmail && props.showIfSubscribed !== true) {
    hidePanel.value = true
  }
})

function submitSubscribe() {
  subscribeLoading.value = true
  formError.value = ''
  errors.value = []

  if (typeof grecaptcha === 'undefined') {
    handleError()
  } else {
    grecaptcha.ready(function () {
      grecaptcha.execute(recaptchaSiteKey.value, {action: 'submit'})
          .then(async function (token) {
            let bodyData = {
              'first_name': firstName.value,
              'email': email.value,
              'google_recaptcha': token
            };

            try {
              await sendRequest(
                  $backendRequest(`${runtimeConfig.public.apiUrl}/api/subscribe`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Accept': 'application/json',
                    },
                    body: JSON.stringify(bodyData)
                  }),
                  (data) => {

                    localStorage.setItem('newsletter-email', email.value)

                    formError.value = ''
                    errors.value = []
                    firstName.value = ''
                    email.value = ''
                    isSubscribed.value = true
                    subscribeLoading.value = false

                  }, (theErrors, message) => {
                    errors.value = theErrors
                    formError.value = message
                    subscribeLoading.value = false
                  }
              )
            } catch (error) {
              handleError()
            }
          })
          .catch(error => {
            handleError()
          })
    })
  }
}

function handleError(message = 'There was a problem processing your request. Please refresh and try again.') {
  subscribeLoading.value = false
  formError.value = message
}

function submitSubscribeAgain() {
  isSubscribed.value = false
}

const showIsSubscribed = computed(() => {
  return isSubscribed.value
})

</script>

<template>
  <div class="subscribe-panel">
    <div
        v-if="! hidePanel"
        class="panel"
        :class="{'is-subscribed': showIsSubscribed}"
    >
      <div class="subscribed p-5">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
            <span>
            <strong>You're receiving our newsletter!</strong>
              <br>
              Check your inbox for a 10% off coupon code.
              </span>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <a
                  @click="submitSubscribeAgain"
                  class="button is-dark is-outlined"
              >
                Subscribe again
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="not-subscribed p-5">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <span v-if="! formError">
                Receive our newsletter and get <strong>10% off</strong> your next order.
                <br>
                Subscribe now!
              </span>
              <span v-else class="notification is-danger">
                <strong>Error:</strong>
                {{ formError }}
              </span>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <input
                  class="input"
                  :class="{'is-danger': errors.first_name}"
                  v-model="firstName"
                  type="text"
                  placeholder="First name"
              >
            </div>
            <div class="level-item">
              <input
                  class="input"
                  :class="{'is-danger': errors.email}"
                  v-model="email"
                  type="email"
                  placeholder="Email address"
                  @keyup.enter="submitSubscribe"
              >
            </div>
            <div class="level-item">
              <a
                  @click="submitSubscribe"
                  class="button is-primary has-text-weight-semibold"
                  :class="{ 'is-loading': subscribeLoading }"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "scss/variables";
@import "bulma/sass/utilities/mixins";

.notification {
  padding: 0.5rem 2.5rem 0.5rem 1.5rem;
}

.input {
  &:active,
  &:hover,
  &:focus {
    border-color: #93b1e7 !important;
    box-shadow: 0 0 0 0.25em rgba(147, 177, 231, 0.6) !important;
  }

  &.is-danger {
    border-width: medium;
  }
}

.level {
  @include until(1408px) {
    display: block;
  }

  .level-left {
    @include until(1408px) {
      text-align: center;
      display: block;
      padding-bottom: 16px;
    }
  }

  .level-right {
    @include until(1408px) {
      justify-content: center;
    }
  }
}

.subscribe-panel {
  padding-top: 32px;
  padding-bottom: 48px;
}

.panel {
  background: linear-gradient(to right, #dc8ac1, #93b1e7);

  @include mobile {
    background: linear-gradient(to bottom, #dc8ac1, #93b1e7);
  }

  .subscribed {
    display: none;
    background: rgba(255, 255, 255, 0.4);
  }

  .not-subscribed {
    background: rgba(220, 138, 193, 0.25);
  }

  &.is-subscribed {
    .not-subscribed {
      display: none;
    }

    .subscribed {
      display: block;
    }
  }
}
</style>